import React, { useState, createRef } from 'react';
import { Map, TileLayer, Marker, Popup, FeatureGroup, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { latLngBounds } from 'leaflet';
import L from 'leaflet';
import { SjText } from './text';
import { SjLink } from './link';
//import Fullscreen from 'react-leaflet-fullscreen-plugin';

const markerIcon = require('../Pages/Page/marker-icon-blue.png');
const markerShadowIcon = require('../Pages/Page/marker-shadow.png');

const greenMarker = require('../Pages/Page/marker-icon-green.png');
const blueMarker = require('../Pages/Page/marker-icon-blue.png');
const redMarker = require('../Pages/Page/marker-icon-red.png');
const blackMarker = require('../Pages/Page/marker-icon-black.png');
const yellowMarker = require('../Pages/Page/marker-icon-yellow.png');

const DIFF_ICONS: any = [
  new L.Icon({
    iconUrl: greenMarker,
    shadowUrl: markerShadowIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  }),
  new L.Icon({
    iconUrl: greenMarker,
    shadowUrl: markerShadowIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  }),
  new L.Icon({
    iconUrl: blueMarker,
    shadowUrl: markerShadowIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  }),
  new L.Icon({
    iconUrl: redMarker,
    shadowUrl: markerShadowIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  }),
  new L.Icon({
    iconUrl: blackMarker,
    shadowUrl: markerShadowIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  }),
  new L.Icon({
    iconUrl: yellowMarker,
    shadowUrl: markerShadowIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  })
]

const iconPerson = new L.Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadowIcon,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export interface ISjMapMarker {
  id: any;
  latLng: any;
  lat: number;
  lng: number;
  name: string;
  difficulty?: number;
  inRoute?: boolean;
  available_from?: string;
  available_to?: string;
  extra?: string;
  number?: number;
  visited?: boolean;
  popupLink?: {
    onClick?(marker: ISjMapMarker): void
    title?: string;
    to?: string;
  }
}

interface IFeatureCollection {
  type: string;
  features: Array<any>;
}

export interface ISjMapProps {
  containerId: string;
  markers: ISjMapMarker[];
  updateOnLayerChange: boolean;
  zoomInit?: number;
  useDifficultyColors?: boolean;
  center?: { lat: number, lng: number };
  style?: React.CSSProperties | undefined;
  featureCollection?: any;
}

export const SjMap = (props: ISjMapProps) => {
  const { markers = [], updateOnLayerChange = undefined, zoomInit, center } = props;
  const mapRef = createRef<Map>();

  React.useEffect(() => {
    if (!markers?.length || mapRef.current === null || updateOnLayerChange !== true) {
      return;
    }
    const bounds = latLngBounds([]);
    markers.forEach((t: any) => {
      bounds.extend(t.latLng);
    });
    mapRef.current?.leafletElement?.fitBounds(bounds);
  }, [markers]);

  const [zoom, setZoom] = useState(zoomInit ? zoomInit : 4);

  React.useEffect(() => {
    if (!center || !center?.lat || !center.lat || !mapRef.current) {
      return;
    }

    mapRef.current?.leafletElement?.flyTo(center, 10, { animate: false });
  }, [center]);

  if (!markers.length)
    return null;

  return (<Map ref={mapRef} id={props.containerId} style={props.style} maxZoom={17} center={{ lat: 64.466291, lng: 11.495593 }} zoom={zoom} scrollWheelZoom={false} zoomControl={true}>
    <TileLayer
      attribution='<a href="http://www.kartverket.no/">Kartverket</a>'
      url='https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4&zoom={z}&x={x}&y={y}'
    />

    {/*<Fullscreen
      position="topleft"
    />*/}

    {props.featureCollection?.type === "FeatureCollection" ?
      <GeoJSON
        data={props.featureCollection}
        style={() => ({
          fillColor: 'transparent',
          weight: 2,
          opacity: 1,
          color: '#0090ff',  //Outline color
          fillOpacity: 0.7
        })} />
      : null}

    <FeatureGroup>
      {markers.map((marker: ISjMapMarker) => (
        <Marker icon={props.useDifficultyColors ? DIFF_ICONS[marker.difficulty!] : iconPerson} key={marker.id} position={{ lat: marker.lat, lng: marker.lng }}>
          <Popup>
            <SjText style={{ color: "#99cc00" }}>{marker.name}</SjText>
            {marker.extra ? <div dangerouslySetInnerHTML={{ __html: marker.extra }}></div> : null}
            {marker.popupLink?.title ? <div>
              <SjLink to={marker.popupLink?.to}>{marker.popupLink.title}</SjLink>
            </div> : null}
          </Popup>
        </Marker>))
      }
    </FeatureGroup>
  </Map>
  )
};