import React, { Suspense, Fragment, useLayoutEffect, useEffect, createRef, useRef } from 'react';
import styled from 'styled-components';
import { Routes, Route, useLocation } from "react-router-dom";

import { SjHeading } from './Components/heading';
import { SjPage } from './Pages/Page/Page.Template';
import { HomePage } from './Pages/Home';
import { SjDownloadPdfsPage } from './Pages/DownloadPdfs/SjDownloadPdfsPage';

const SjContactPage = React.lazy(() => import('./Pages/Help/Contact/ContactPage'));
const SjPasswordPage = React.lazy(() => import('./Pages/Password/PasswordPage'));
const SjLoginPage = React.lazy(() => import('./Pages/Account/Login'));
const SjSignupPage = React.lazy(() => import('./Pages/Account/SignUp'));
const SjAreasPage = React.lazy(() => import('./Pages/Organizers/Areas'));
const SjOrganizerPage = React.lazy(() => import('./Pages/Organizers/Organizer'));
const SjHelpPage = React.lazy(() => import('./Pages/Help/Help'));
const SjNewsPage = React.lazy(() => import('./Pages/News/News'));
const SjNewsSingle = React.lazy(() => import('./Pages/News/News.Single'));
const SjProfilePage = React.lazy(() => import('./Pages/Profile/Profile'));
const SjVisitsPage = React.lazy(() => import('./Pages/Profile/Visits/Visits.Page'));
const SjRegisterVisitsPage = React.lazy(() => import('./Pages/RegisterVisit/RegisterVisits.Page'));
const SjGroupsPage = React.lazy(() => import('./Pages/Profile/Groups/Groups.Page'));
const SjFamilyPage = React.lazy(() => import('./Pages/Profile/Family/Family.Page'));
const SjLogoutPage = React.lazy(() => import('./Pages/Account/Logout'));
const SjToplistPage = React.lazy(() => import('./Pages/Toplists/Toplist.Page'));

const SjRouteInitPage = React.lazy(() => import('./Pages/Routes/NewRoute.page'));
const SjMyRoutesPage = React.lazy(() => import('./Pages/Routes/MyRoutes.page'));
const SjEditRoutePage = React.lazy(() => import('./Pages/Routes/EditRoute.page'));
const SjRoutePoleInfoPage = React.lazy(() => import('./Pages/Routes/RoutePoleInfo.page'));
const SjRouteEditQuizPage = React.lazy(() => import('./Pages/Routes/QuizQuestion.page'));
const SjRouteQuizPage = React.lazy(() => import('./Pages/Routes/RouteQuiz.page'));
const SjRouteNewQuizPage = React.lazy(() => import('./Pages/Routes/NewQuiz.page'));
const SjRouteSummaryPage = React.lazy(() => import('./Pages/Routes/RouteSummary.page'));
const SjRouteFoldersPage = React.lazy(() => import('./Pages/Routes/RouteFolders.page'));

const SjActivateAccountPage = React.lazy(() => import('./Pages/Account/ActivateAccount'));
const SjAreasOverviewPage = React.lazy(() => import('./Pages/AreasOverview/areasOverviewPage'));
const SjAreaVisitsMapPage = React.lazy(() => import('./Pages/AreasOverview/areaVisitsMapPage'));

const Content = styled.div<any>`
  min-height:100vh;
  background-color: ${props => props.theme.colors.backgroundColor};
`;

const NoMatch = () => {
  const { pathname } = useLocation();
  return <div style={{ padding: 20, textAlign: 'center' }}>
    <SjHeading>404 - Fant ikke siden: {pathname} </SjHeading>
  </div>
}

export function SjContent() {
  const { pathname } = useLocation();
  const ref = useRef();


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [pathname]);

  return (
    <Content ref={ref}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/logginn" element={<Suspense fallback={<div />}><SjLoginPage /></Suspense>} />
        <Route path="/omraader" element={<Suspense fallback={<div />}><SjAreasPage /></Suspense>} />
        <Route path="/områder" element={<Suspense fallback={<div />}><SjAreasPage /></Suspense>} />
        <Route path="/arrangor/:alias" element={<Suspense fallback={<div />}><SjOrganizerPage /></Suspense>} />
        <Route path="/arrangør/:alias" element={<Suspense fallback={<div />}><SjOrganizerPage /></Suspense>} />
        <Route path="/områder" element={<Suspense fallback={<div />}><SjAreasPage /></Suspense>} />
        <Route path="/konseptet" element={<SjPage title="Konseptet" id="konseptet" />} />
        <Route path="/organisasjon" element={<SjPage title="Om Stolpejakten" id="organisasjon" />} />
        <Route path="/betalbarekart" element={<SjPage title="Betalbare kart" id="betalbarekart" />} />
        <Route path="/privacy" element={<SjPage title="Personvernerklæring" id="privacy" />} />
        <Route path="/motd" element={<SjPage title="Fra Stolpejakten" id="motd" />} />
        <Route path="/topplister" element={<Suspense fallback={<div />}><SjToplistPage /></Suspense>} />
        <Route path="/kart" element={<Suspense fallback={<div />}><SjDownloadPdfsPage /></Suspense>} />
        <Route path="/hjelp" element={<Suspense fallback={<div />}><SjHelpPage /></Suspense>} />
        <Route path="/faq" element={<Suspense fallback={<div />}><SjHelpPage /></Suspense>} />
        <Route path="/nyheter" element={<Suspense fallback={<div />}><SjNewsPage /></Suspense>} />
        <Route path="/nyheter/:slug" element={<Suspense fallback={<div />}><SjNewsSingle /></Suspense>} />
        <Route path="/kontakt" element={<Suspense fallback={<div />}><SjContactPage /></Suspense>} />
        <Route path="/ny" element={<Suspense fallback={<div />}><SjSignupPage /></Suspense>} />

        <Route path="/profil" element={<Suspense fallback={<div />}><SjProfilePage /></Suspense>} />
        <Route path="/profil/besoksoversikt" element={<Suspense fallback={<div />}><SjVisitsPage /></Suspense>} />
        <Route path="/profil/grupper" element={<Suspense fallback={<div />}><SjGroupsPage /></Suspense>} />
        <Route path="/profil/familiemedllemmer" element={<Suspense fallback={<div />}><SjFamilyPage /></Suspense>} />
        <Route path="/stolperegistrering" element={<Suspense fallback={<div />}><SjRegisterVisitsPage /></Suspense>} />
        <Route path="/profil/omraader" element={<Suspense fallback={<div />}><SjAreasOverviewPage /></Suspense>} />
        <Route path="/profil/omraader/:id" element={<Suspense fallback={<div />}><SjAreaVisitsMapPage /></Suspense>} />

        <Route path="/ruter" element={<Suspense fallback={<div />}><SjMyRoutesPage /></Suspense>} />
        <Route path="/ruter/ny" element={<Suspense fallback={<div />}><SjRouteInitPage /></Suspense>} />
        <Route path="/ruter/info" element={<Suspense fallback={<div />}><SjRoutePoleInfoPage /></Suspense>} />
        <Route path="/ruter/quiz" element={<Suspense fallback={<div />}><SjRouteQuizPage /></Suspense>} />
        <Route path="/ruter/quiz/ny" element={<Suspense fallback={<div />}><SjRouteNewQuizPage /></Suspense>} />
        <Route path="/ruter/quiz/:quizId" element={<Suspense fallback={<div />}><SjRouteEditQuizPage /></Suspense>} />
        <Route path="/ruter/mapper" element={<Suspense fallback={<div />}><SjRouteFoldersPage /></Suspense>} />
        <Route path="/ruter/rediger/:routeId" element={<Suspense fallback={<div />}><SjEditRoutePage /></Suspense>} />
        <Route path="/ruter/:routeId" element={<Suspense fallback={<div />}><SjRouteSummaryPage /></Suspense>} />

        <Route path="/passord" element={<Suspense fallback={<div />}><SjPasswordPage /></Suspense>} />
        <Route path="/aktiver/:code" element={<Suspense fallback={<div />}><SjActivateAccountPage /></Suspense>} />

        <Route path="/loggut" element={<Suspense fallback={<div />}><SjLogoutPage /></Suspense>} />

        <Route path='*' element={<NoMatch />} />
      </Routes>
    </Content >
  );
}