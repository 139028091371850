import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faFacebook, faApple } from '@fortawesome/free-brands-svg-icons';
import {
  faMoon, faArrowLeft, faArrowRight, faSun, faTimes,
  faInfoCircle, faSignOutAlt, faCheckCircle, faExclamation,
  faSignInAlt, faPlus, faHome, faExclamationCircle, faEye, faEyeSlash, faTrash,
  faLock, faClock, faExclamationTriangle, faSpinner, faSearchLocation, faSearch,
  faUser, faCopy, faFileDownload, faMinus, faArrowUp, faArrowDown, faSave, faPen, faCheck,
  faSquare, faCheckSquare, faChevronRight, faChevronLeft, faChevronUp, faChevronDown,
  faFolder, faPlusSquare, faMinusSquare, faFile, faFolderOpen, faCaretLeft, faCaretDown,
  faCaretRight, faEdit, faQuestionCircle, faHandPointLeft, faHandPointRight
} from '@fortawesome/free-solid-svg-icons';
library.add(
  fab, faFacebook, faSun, faMoon, faSearch,
  faCheckCircle, faExclamation, faSearchLocation,
  faSignInAlt, faPlus, faHome, faSpinner,
  faExclamationCircle, faLock, faClock, faSignOutAlt,
  faExclamationTriangle, faTimes, faInfoCircle, faPlusSquare, faMinusSquare,
  faArrowRight, faArrowLeft, faEyeSlash, faEye, faSave, faPen, faTrash, faFile, faFolderOpen,
  faUser, faCopy, faFileDownload, faApple, faMinus, faArrowDown, faArrowUp, faFolder,
  faCheck, faSquare, faCheckSquare, faChevronRight, faChevronLeft, faChevronUp, faChevronDown,
  faCaretLeft, faCaretDown, faCaretRight, faEdit, faQuestionCircle, faHandPointLeft, faHandPointRight
);

export const SjIcon = (props: any) => {
  return (<FontAwesomeIcon title={props.title} className={props.className} icon={props.icon} size={props.size} color={props.color} fill={props.fill} style={props.style} />)
}